@charset "UTF-8";
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
:host {
  width: 100%;
  height: 100%;
  position: absolute;
}

.item-context-menu-froala {
  letter-spacing: 0.05rem;
  display: block;
  width: 100%;
  background-color: #f5f5f5;
  height: 35px;
  cursor: pointer;
}

.item-context-menu-froala-content {
  width: 100%;
  height: 100%;
}

.icon-right-button {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #333333;
  right: 2px;
  top: 18px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  content: "";
  margin-left: 3px;
}

.custom-froala-button {
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0px 6px 0px;
}

.card-cell-title-container {
  display: flex;
  height: 100%;
  justify-content: center;
}

.avatar-image-container {
  float: left;
  margin: auto;
  width: 40px;
  height: 40px;
  display: flex;
}

.custom-drop-down-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a {
  padding: 0px !important;
}
.custom-drop-down-menu .fr-dropdown-list {
  padding: 0px !important;
}

.container-icon-froala-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.custom-froala-dropdown-option {
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 6px;
  width: max-content;
}
.custom-froala-dropdown-option .dropwdown-option-container {
  background: #e7e7e7;
  margin-right: 6px;
  width: 38px;
  text-align: -webkit-center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.custom-froala-dropdown-option.top-separator {
  border-top: 1px solid #c3c3c3;
}

.notification-title {
  font-size: 12px;
}

.notification-bottom-text {
  font-size: 10.5px;
  color: #909090;
  margin-top: 6px;
}

.delete-user-option {
  width: 13px;
  padding-right: 0px;
  padding-left: 5px;
  cursor: pointer;
}

.delete-user-icon {
  font-size: 13px;
  color: #ea8278;
}

.user-card-container {
  display: flex;
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  padding: 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  background: #f2f2f2;
}

.header-notifications-group-container {
  display: flex;
  align-items: center;
  width: 100%;
  background: white;
  padding-left: 5px !important;
  padding-top: 6px;
}

.notifications-group-name {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid white;
  font-size: 15px !important;
  padding: 4px;
}

.custom-froala-toolbar-container {
  width: 100%;
  border-right: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
}

.custom-froala-toolbar-group {
  display: inline-flex;
  width: 100%;
}

.notifications-container {
  width: 100%;
  border-right: 1px solid #c1c1c1;
  border-left: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  padding-bottom: 5px;
}

.add-user-section-content {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding-left: 5px;
}

.check-button-container {
  height: 100%;
  display: flex;
  align-items: center;
}

html body p {
  margin: 0px;
}

.dx-datebox-wrapper .dx-popup-bottom .dx-button {
  min-width: 50px !important;
}

.biometric-access-error-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0px 15px 0px 15px;
}

.custom-title {
  width: 100%;
  padding-top: 0px;
  color: #fff !important;
  border-bottom: 0 !important;
  padding: 0 10px !important;
  display: flex;
  align-items: center;
  padding: 0px 10px 4px 10px !important;
  background-color: var(--bgbw2) !important;
  font-size: 24px;
  height: 100%;
}

.second-toolbar {
  border-radius: 0px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--bgbw2) !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--bgbw2) !important;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 50%;
}

ion-app {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.web-platform-font {
  font-family: Segoe UI !important;
  font-size: 14px;
}
.web-platform-font .custom-input .dx-texteditor-input {
  font-family: Segoe UI !important;
  font-size: 14px;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-item-content.dx-list-item-content {
  padding: 0px !important;
}

.counts-bar-more-items .count-bar-right-1 {
  background: #C94646;
}
.counts-bar-more-items .count-bar-right {
  background: #C94646;
}
.counts-bar-more-items .count-bar-right-0 {
  background: #C94646;
}
.counts-bar-more-items .count-bar-right-column-header {
  background: #C94646;
}

.nagivation-filters-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  background-color: #DDDDDD;
  height: auto !important;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0px;
  min-height: 38px;
}
.nagivation-filters-container .btn-detail-toolbar {
  height: 30px !important;
}
.nagivation-filters-container .content-filters {
  flex-wrap: wrap !important;
}
.nagivation-filters-container .content-buttons {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}
.nagivation-filters-container .toolbar-separator {
  margin-left: 13px !important;
}
.nagivation-filters-container .disabled-filter {
  margin-top: 4px;
  margin-bottom: 4px;
}
.nagivation-filters-container .enabled-filter {
  margin-top: 4px;
  margin-bottom: 4px;
}

.count-bar-right-tree-0 {
  background: #5481B4;
  border-radius: 0px 4px 4px 0px;
  padding: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  height: 20px;
  display: flex;
  align-items: center;
}

.count-bar-right-tree-1 {
  background: #5481B4;
  border-radius: 4px 4px 4px 4px;
  padding: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  height: 20px;
  display: flex;
  align-items: center;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.card-tree-mode {
  width: 100%;
  height: 100%;
  letter-spacing: 0.02rem;
  display: block;
  background: #ffffff;
  text-align: left;
  border-bottom: 1px solid #cccccc;
}

.content-icon-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px 8px 0px;
  width: 30px;
  cursor: pointer;
}

.card-content-image-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.cards-tree-header {
  background: #DDDDDD !important;
}

.mobile-mode .card-list-tree-scrollable {
  width: 100%;
  padding-right: 0px;
}

.card-list-tree-scrollable {
  overflow-x: hidden;
  position: relative;
  background: #f2f2f2;
}

.card-list-wrapper-tree-mode {
  position: relative;
  overflow-x: auto;
  background: #f2f2f2;
}

@-moz-document url-prefix() {
  .card-list-wrapper-tree-mode {
    scrollbar-width: thin;
  }

  .card-list-tree-scrollable {
    scrollbar-width: thin;
  }
}
.dx-scheduler-appointment-title {
  display: block !important;
}

.dx-scheduler-appointment-content-details {
  display: block !important;
}

.text-light {
  color: #f8f9fa !important;
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.back-button-container {
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 15px;
}

.lock-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000000000000000000000000000000;
}
.lock-screen .lock-screen-container {
  text-align: -webkit-center;
  height: 60%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.pop-up-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 11;
}

.context-menu-froala-container {
  z-index: 11;
  position: absolute;
  overflow: auto;
  min-width: 150px;
  width: min-content;
}

.context-menu-froala-container.mobile-mode::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.updating-window-container {
  color: #fff;
  background-color: #003474;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  height: 100%;
  position: relative;
  background: #003474;
  z-index: 9;
}

.approve-login-web-container {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #003474;
  z-index: 100000000000000010000000000000;
}

.approve-login-web-panel {
  width: 340px;
  border: 1px solid white;
  text-align: center;
  text-align: -webkit-center;
  height: 60%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.approve-login-web-panel-content {
  width: 270px !important;
}

.title-message {
  text-align: center;
  color: white;
  padding-top: 50px;
  font-size: 26px;
  font-weight: bold;
}

.text-message {
  text-align: center;
  color: white;
  padding-top: 30px;
  font-size: 14px;
}

.button-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.btn-submit-approve-login {
  background-color: #ffffff;
  color: #003474;
  border: 0;
  height: 40px;
  width: 80%;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-submit {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #003474;
  border: 0;
  height: 30px;
  width: 100%;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: 1.15;
}

a {
  color: unset;
}

.ion-page > ion-content {
  background-image: url("/assets/img/logo_home.png");
  background-color: #FFFFFF;
  background-repeat: no-repeat;
  background-position: 50% 45%;
  background-size: 80%;
}

.ion-page {
  position: fixed;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

ion-header {
  padding-top: calc(constant(safe-area-inset-top) - 14px);
  padding-top: calc(env(safe-area-inset-top) - 14px);
  background-color: var(--bw2-darksoft);
}

ion-header ion-toolbar:first-child {
  padding-top: calc(constant(safe-area-inset-top) - 52px);
  padding-top: calc(env(safe-area-inset-top) - 52px);
}

.header-md:after {
  background-image: none;
}

ion-content {
  letter-spacing: 0.05rem !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 3px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aeaeae !important;
  border-radius: 3px !important;
  min-height: 200px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aeaeae !important;
}

@-moz-document url-prefix() {
  .dx-scrollable-container {
    scrollbar-width: thin;
  }
}
.dx-list-item-content {
  display: block;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll {
  min-height: 300px !important;
}

dx-scroll-view.dx-scrollable.dx-scrollview.dx-visibility-change-handler.dx-scrollable-vertical.dx-scrollable-simulated.dx-scrollable-customizable-scrollbars {
  overflow: hidden !important;
}

ion-footer {
  height: 45px !important;
  position: absolute !important;
  bottom: 0;
}

.slide-zoom {
  position: absolute;
  top: 0;
}

.icon-view {
  position: absolute;
  z-index: 0;
}

.icon-main-view {
  width: 80px;
  height: 80px;
  position: relative;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  max-width: 200px;
  min-width: 200px;
  border-right: 0px;
}

.split-pane-ios.split-pane-visible > .split-pane-side {
  max-width: 200px;
  min-width: 200px;
}

.dx-scheduler-date-time-indicator {
  z-index: 0;
}

.menu-inner {
  width: 250px;
}

.toolbar-title {
  text-align: center;
  color: #fff;
}

.card-detail-header-one-line {
  visibility: visible !important;
  padding: 15px 0px 0px 12px !important;
}
.card-detail-header-one-line .custom-title-card {
  font-size: 20px !important;
}
.card-detail-header-one-line .custom-subtitle-card {
  -webkit-line-clamp: 3;
}

.card-detail-header-two-line {
  visibility: visible !important;
  padding: 10px 0px 0px 12px !important;
}
.card-detail-header-two-line .custom-title-card {
  font-size: 18px !important;
}
.card-detail-header-two-line .custom-subtitle-card {
  -webkit-line-clamp: 2;
}

.card-detail-header-three-line {
  visibility: visible !important;
  padding: 5px 0px 0px 12px !important;
}
.card-detail-header-three-line .custom-title-card {
  font-size: 15px !important;
}
.card-detail-header-three-line .custom-subtitle-card {
  -webkit-line-clamp: 1;
}

.custom-subtitle-card {
  font-size: 12px;
  margin-left: 21px;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  display: -webkit-inline-box;
}

.custom-title-card {
  font-weight: bold;
  margin-bottom: 5px;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 20px;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-inline-box;
  padding-bottom: 2px;
}

.truncated {
  display: block;
  line-height: normal;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;
}
.truncated:after {
  content: "";
}

.truncated-2 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  line-height: normal;
  overflow: hidden;
  white-space: normal !important;
}

.truncated-caption-filter {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.toolbar.fa {
  color: #5783b2;
  width: 50px;
  height: 100%;
  vertical-align: bottom;
}
.toolbar.fa.filter-active {
  color: #14afef;
}
.toolbar.fa:before {
  font-size: 2rem;
  line-height: 35px;
}

.navbar-fa-icon {
  color: #ffffff;
  font-size: 1rem;
}

ion-toolbar {
  height: 52px;
  padding-right: calc(constant(safe-area-inset-top) - 32px);
  padding-right: calc(env(safe-area-inset-top) - 32px);
  padding-left: calc(constant(safe-area-inset-top) - 32px);
  padding-left: calc(env(safe-area-inset-top) - 32px);
}

ion-buttons button ion-icon {
  font-size: 2.5rem;
  color: #5783b2;
}

ion-navbar.toolbar {
  padding: 0px;
}

* .ionicon {
  font-size: 0.75em;
}

.custom-fa {
  color: #767676;
  font-size: 0.75rem;
  margin-right: 4px;
}
.custom-fa.fa-phone-square {
  color: #14afef;
}
.custom-fa.fa-file-text-o {
  color: #3465A4;
}
.custom-fa.fa-file-pdf-o {
  color: #ff3b30;
}
.custom-fa.fa-file-excel-o {
  color: #008641;
}
.custom-fa.fa-file-powerpoint-o {
  color: #D04727;
}
.custom-fa.fa-file-video-o, .custom-fa.fa-file-audio-o {
  color: #CB9EC7;
}
.custom-fa.fa-file-code-o, .custom-fa.fa-file-archive-o, .custom-fa.fa-file-word-o {
  color: #007aff;
}
.custom-fa.fa-file-o, .custom-fa.fa-file-image-o {
  color: #37aafc;
}
.custom-fa.fa-flag.red {
  color: red;
}
.custom-fa.fa-flag.blue {
  color: blue;
}
.custom-fa.fa-flag.green {
  color: green;
}
.custom-fa.fa-flag.orange {
  color: orange;
}
.custom-fa.fa-flag.purpur {
  color: purple;
}
.custom-fa.fa-flag.yellow {
  color: yellow;
}
.custom-fa.fa-flag.gray {
  color: gray;
}
.custom-fa.fa-fire.red {
  color: red;
}
.custom-fa.fa-fire.blue {
  color: blue;
}
.custom-fa.fa-fire.green {
  color: green;
}
.custom-fa.fa-fire.orange {
  color: orange;
}
.custom-fa.fa-fire.purpur {
  color: purple;
}
.custom-fa.fa-fire.yellow {
  color: yellow;
}
.custom-fa.fa-fire.gray {
  color: gray;
}

.rotate-90 {
  display: inline-block;
  transform: rotate(90deg);
}

.rotate-135 {
  display: inline-block;
  transform: rotate(135deg);
}

.page-title {
  line-height: 0px;
  left: 0px;
  right: 0px;
  position: fixed;
}

.page-sub-title {
  padding-top: 8px;
  color: #14afef !important;
  font-size: 0.7rem !important;
  left: 0px;
  right: 0px;
  position: fixed;
}
.page-sub-title i {
  color: #14afef;
}
.page-sub-title.detail {
  padding-top: 24px;
}

.item-inner {
  border-width: 0px !important;
  box-shadow: inset 0 0 0 0 #dedede !important;
}

.text-input {
  margin: 0;
}

.select {
  padding: 0;
}

ion-select {
  max-width: 80% !important;
}

ion-checkbox {
  margin: 0 !important;
}

.input-wrapper {
  padding-top: 2px;
  padding-bottom: 2px;
}

ion-item-divider {
  color: #000 !important;
  background-color: #f5f5f5 !important;
  padding: 2px !important;
  border-bottom: 0 !important;
}

.mobile-mode .custom-input {
  border: 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #ebebeb !important;
}
.mobile-mode .custom-input .dx-texteditor-input {
  min-height: 35px !important;
}
.mobile-mode .custom-radio-group .dx-widget {
  padding-left: 0px;
  float: right;
}
.mobile-mode .custom-check-box {
  padding-left: 10px;
}

.custom-input {
  width: auto;
}
.custom-input .dx-texteditor-input {
  min-height: 25px !important;
}
.custom-input .dx-dropdowneditor-input-wrapper {
  width: 100%;
}

.property-item-focused .custom-input {
  background: #ffff80 !important;
}
.property-item-focused .selected-emails {
  background: #ffff80 !important;
}
.property-item-focused .property-caption {
  border-bottom: 1px solid #ffff80 !important;
}

.mobile-mode .property-item-focused .custom-input {
  background: #fff !important;
}
.mobile-mode .property-item-focused .selected-emails {
  background: #fff !important;
}
.mobile-mode .property-item-focused .property-caption {
  border-bottom: 1px solid #fff !important;
}

.custom-froala .fr-wrapper {
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-right: 1px solid #CCCCCC !important;
  border-left: 1px solid #CCCCCC !important;
}

.custom-input-text {
  border: 0;
}

.fr-toolbar.fr-top {
  border-radius: 0px !important;
}

.selected-item {
  background: #A2E2FD !important;
  -webkit-tap-highlight-color: #A2E2FD !important;
}

.last-selected-multiple-items {
  background: #FFF9CA !important;
  -webkit-tap-highlight-color: #fff29d !important;
}

.last-selected-item {
  background: #FFF498 !important;
  -webkit-tap-highlight-color: #fff29d !important;
}

ion-loading {
  z-index: 99999 !important;
}

.toolbar-title {
  font-size: 0.6rem;
}

.bar-button {
  font-size: 0.6rem;
}

.slide-zoom {
  left: 0;
}

ion-label {
  margin: 0 !important;
}

ion-item {
  min-height: auto !important;
  padding-top: 0px !important;
  padding-bottom: 13x !important;
  padding-left: 0px !important;
}

ion-item.ion-item-card::part(native) {
  -webkit-padding-start: unset !important;
  padding-inline-start: unset !important;
  -webkit-padding-end: unset !important;
  padding-inline-end: unset !important;
}

ion-item-options {
  padding-bottom: 13x !important;
}

.bar-button-menutoggle {
  display: -webkit-inline-box;
}

.item-block {
  min-height: 1px;
}

.item-md {
  padding-left: 8px;
}

.item-md.item-block .item-inner {
  padding-right: 0px !important;
}

.bar-button-md {
  text-transform: initial;
  color: var(--ion-color-primary, #3880ff) !important;
  position: relative;
  display: -webkit-inline-box;
}

.back-button-md {
  display: none;
}

.scroll-content {
  padding: 0px !important;
  overflow-y: auto;
}

.counts-bar {
  padding: 2px;
  display: inline-block;
  top: 3px !important;
}

.counts-bar-modal {
  padding: 0px;
  display: flex;
  align-items: center;
}

.counts-bar-list-toolbar {
  padding: 0px;
  display: flex;
  align-items: center;
  padding-left: 6px;
}

.counts-bar-menu {
  padding: 0px 3px 0px 0px;
  display: flex;
  height: 28px;
  align-items: center;
}

.counts-bar-plugin {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
}

.content-bottom-plugin {
  display: flex;
  padding-top: 7px;
}

.content-bottom-plugin-card-detail {
  display: flex;
  padding-top: 2px;
}

.count-bar-left-navbar {
  background: #5381b3;
  display: inline;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
}

.count-bar-right-navbar {
  background: #649ad8;
  display: inline;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
}

.count-border-left-navbar {
  border-radius: 10px 0px 0px 10px;
  padding: 2px 5px 2px 5px;
}

.count-border-left-navbar-2 {
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
}

.count-border-right-navbar {
  border-radius: 0px 10px 10px 0px;
  padding: 2px 5px 2px 5px;
}

.count-border-right-navbar-2 {
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
}

.show-pipe-plugin {
  width: 25px !important;
  padding: 24px 0px 6px 7px;
}

.count-bar-left {
  background: #2DA8FF;
  border-radius: 10px 0px 0px 10px;
  display: inline;
  padding: 2px 2px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
}

.count-bar-left-2 {
  background: #2DA8FF;
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  display: inline;
}

.count-bar-right-0 {
  background: #5481B4;
  border-radius: 0px;
  display: inline;
  padding: 2px 0px 2px 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
}

.count-bar-right-1 {
  background: #5481B4;
  border-radius: 4px 0px 0px 4px;
  padding: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  display: inline;
}

.count-bar-right {
  background: #5481B4;
  border-radius: 0px 10px 10px 0px;
  display: inline;
  padding: 2px 5px 2px 4px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
}

.count-bar-right-2 {
  background: #5481B4;
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  display: inline;
}

.count-bar-right-column-header {
  background: #5481B4;
  border-radius: 10px;
  padding: 2px 5px 2px 5px;
  color: #fff;
  font-size: 0.7rem;
  font-weight: 400;
  float: right;
}

.hidden-count-bar {
  background: #384148;
  padding: 2px 5px 2px 5px;
  color: #384148;
  font-size: 0.6rem;
  font-weight: 100;
  display: inline;
}

.context-menu-text {
  min-height: 30px !important;
  line-height: 30px;
  display: flex;
  align-items: center;
}

.context-menu-icon {
  background: #d3d3d3;
  min-width: 24px;
  padding-left: 4px;
  max-width: 24px;
  display: flex;
  align-items: center;
}

.toolbar-md {
  min-height: 30px;
}

.button-md {
  text-transform: none;
}

.bw2CommentPluginFigure {
  margin: 1em 4px !important;
  position: relative;
}

.bw2CommentPluginFigcaption div {
  position: relative !important;
  float: right;
}

.bw2CommentPluginFigure div {
  display: inline-table;
}

.icon-svg {
  height: 15px;
  width: 16px;
  float: left;
  margin-right: 0px;
}
.icon-svg path,
.icon-svg circle {
  fill: #8e8e8e;
}

.icon-svg-toolbar {
  height: 16px;
  margin-right: 2px;
  float: left;
  max-width: unset;
}
.icon-svg-toolbar path,
.icon-svg-toolbar circle {
  fill: #8e8e8e;
}

.icon-svg-toolbar-filter {
  height: 23px;
  float: left;
  max-width: unset;
}
.icon-svg-toolbar-filter path,
.icon-svg-toolbar-filter circle {
  fill: #8e8e8e;
}

.icon-svg-toolbar-filter-container {
  cursor: pointer;
}

.custom-toolbar-separator {
  background: #b0b0b0;
  width: 1px;
  height: 30px;
  display: inline-flex;
}

.list-md {
  padding: 0px !important;
}

ion-item-sliding {
  z-index: 0;
}

ion-item-sliding.active-slide .item,
ion-item-sliding.active-slide .item.activated {
  min-height: 50px !important;
}

button:focus {
  outline: none !important;
}

.dx-dialog-message {
  text-align: center;
  padding: 10px 10px 5px 10px !important;
}

.icon-filter {
  padding-top: 0px;
  padding-bottom: 1px;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-after {
  padding: 0 !important;
  right: unset !important;
}

.dx-popup-bottom.dx-toolbar .dx-toolbar-items-container {
  display: flex !important;
  justify-content: center !important;
}

.dx-scheduler-time-panel {
  margin-top: 0px !important;
  font-size: 0.9rem !important;
}

.dx-scheduler-small .dx-scheduler-date-table {
  margin-left: -50px !important;
}

.dx-scheduler-small .dx-scheduler-work-space-month .dx-scheduler-date-table {
  margin-left: 0px !important;
}

.dx-scheduler-header-row {
  font-size: 0.9rem !important;
}

.dx-scheduler-header-panel-cell {
  height: 20px !important;
}

.dx-scheduler-header-panel {
  margin-top: 2px !important;
}

.dx-scheduler-all-day-title {
  top: 77px !important;
}

.dx-scrollable-wrapper {
  border-bottom: none;
  display: inherit;
}

.scroll-view-table-mode .dx-scrollable-wrapper {
  overflow-y: hidden;
}

.dx-scheduler-appointment {
  min-width: 50px;
}

.dx-scheduler-appointment-tooltip-buttons {
  margin-bottom: 5px;
  margin-left: 5px;
}

.dx-toast-warning {
  visibility: hidden !important;
}

.hidden-toast .dx-toast-custom {
  background: transparent;
}

.custom-toast-uploading .dx-toast-content {
  background-color: purple;
  margin-top: -11px !important;
}

.custom-toast-uploading-ios .dx-toast-content {
  background-color: purple;
  margin-top: 8px !important;
}

.custom-toast-app-version .dx-toast-content {
  background-color: green;
  margin-top: -11px !important;
}

.custom-toast-app-version-ios .dx-toast-content {
  background-color: green;
  margin-top: 8px !important;
}

.dx-toast-icon {
  width: 16px !important;
  height: 16px !important;
}

.dx-toast-success {
  height: 35px !important;
}

.dx-menu-item {
  color: #000 !important;
}

.dx-menu-base {
  font-size: 0.8rem !important;
  letter-spacing: 0.01rem !important;
}

.dx-context-menu .dx-menu-items-container {
  padding: 0px !important;
}

.dx-menu-base .dx-menu-item-content {
  padding: 0px !important;
}

.dx-popup-title {
  background-color: #436d98 !important;
  color: #ffffff !important;
  border-bottom: 0px !important;
  padding: 0px !important;
  padding-top: 0px !important;
  display: flex;
  align-items: center;
}

.dx-popup-title.dx-toolbar {
  padding-left: 10px !important;
}

.dx-popup-title.dx-toolbar {
  background-color: var(--bgbw2);
  color: #ffffff !important;
}

.dx-popup-title .dx-closebutton .dx-icon {
  color: #3aaafe !important;
}

.dx-popup-content {
  padding: 0px !important;
  background-color: #ffffff;
  width: 100%;
}

.item-context-menu {
  letter-spacing: 0.05rem;
  display: -webkit-inline-box;
  width: 100%;
  background-color: #f5f5f5;
}

.dx-overlay-shader {
  background: transparent !important;
}

.dx-texteditor {
  font-weight: 700 !important;
}

.toolbar-ios {
  min-height: 30px !important;
}

.header-portals-pop-up {
  width: 100%;
  padding-top: 0px;
  color: #fff !important;
  border-bottom: 0 !important;
  padding: 0 10px !important;
  display: flex;
  align-items: center;
  padding: 0px 10px 4px 10px !important;
  cursor: move;
  font-size: 24px;
  height: 32px !important;
  background: #003474;
}

.portals-pop-up .portals-selection-container {
  width: 262.8px !important;
  display: inline-grid;
  position: relative;
}
.portals-pop-up .custom-label {
  text-transform: uppercase;
  width: 262.8px;
  float: left;
  font-size: 0.8rem;
  color: #ffffff;
  text-align: left;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.portals-pop-up .custom-select-box {
  margin-top: 2px;
  width: 262.8px;
  border: 0;
  font-size: 15px;
}
.portals-pop-up .portals-pop-up-container {
  text-align: -webkit-center;
  height: 60%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.portals-pop-up .dx-texteditor.dx-editor-outlined {
  background: white;
}
.portals-pop-up .dx-popup-content {
  background: #003474;
  color: white;
}
.portals-pop-up .dx-placeholder {
  color: black;
  font-weight: normal;
}
.portals-pop-up .dx-selectbox {
  color: black;
  font-weight: normal;
}

.dx-popup-bottom.dx-toolbar {
  padding: 8px !important;
}

.btn-toolbar {
  height: 30px;
  padding: 0px;
  color: #222222;
  display: flex;
  align-items: center;
  min-width: 20px;
  cursor: pointer;
}
.btn-toolbar .fa {
  font-size: 1rem;
}

.btn-txt-toolbar {
  height: 35px;
  color: #222222;
  text-transform: capitalize;
  padding: 0px;
  vertical-align: middle;
  font-size: 0.75rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
}
.btn-txt-toolbar .icon {
  font-size: 1rem;
}

.dx-texteditor-input {
  min-height: 35px !important;
}

.mobile-mode .title-edit-item {
  background-color: var(--bgbw2) !important;
  font-size: 24px;
  height: 32px !important;
}
.mobile-mode .title-edit-item .item-image {
  width: 21px !important;
}
.mobile-mode .title-edit-item .item-image .image-si {
  width: 21px !important;
  height: 20px !important;
}

.custom-froala-readonly .fr-dropdown-menu {
  display: none !important;
}

.title-edit-item {
  width: 100%;
  padding-top: 0px;
  background-color: #436d98 !important;
  color: #fff !important;
  border-bottom: 0 !important;
  padding: 0 10px !important;
  display: flex;
  align-items: center;
  padding: 0px 10px 4px 10px !important;
  cursor: move;
}

.list-toolbar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  background-color: #DDDDDD;
  height: 32px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
}
.list-toolbar .sup-left {
  float: left;
  display: flex;
  align-items: center;
  width: 80%;
  padding-left: 4px;
}
.list-toolbar .sup-right {
  float: right;
  width: 20%;
  display: block;
}

.list-toolbar > .btn-toolbar:nth-child(1) {
  padding: 0px 14px 0px 14px !important;
}

.preview-scroll .dx-scrollable-container {
  overflow-x: auto !important;
}

.image-si {
  width: 15px !important;
  height: 15px !important;
}

.image-si-title-card-detail {
  height: 17px !important;
}

.img-plugin {
  height: 16px;
  padding-bottom: 0px;
}

.item-image {
  text-align: center;
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn-detail-toolbar {
  height: 35px;
  padding: 0px !important;
  margin-right: 4px;
  margin-left: 10px;
  vertical-align: middle;
  text-transform: initial;
  color: #222222;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btn-detail-toolbar .fa {
  font-size: initial;
}

.document-thumbnail-container {
  width: 80px;
  height: 60px;
  margin: 0px 20px 0px 8px;
  background: white;
  overflow: hidden;
  border: 0.065rem solid #dedede;
}

.container-card-icons {
  display: block !important;
  margin-top: 2px;
  width: 100%;
}
.container-card-icons .icon-svg {
  vertical-align: bottom;
  float: unset;
}

.container-card-line {
  display: block !important;
  margin-top: 2px;
  width: 100%;
}

.container-card-icons > .card-icon-item:nth-child(1) {
  margin-left: 0px !important;
}

.card-content-table-mode .card-table:nth-child(1) {
  display: flex;
  align-items: center;
}
.card-content-table-mode .card-table:nth-child(1) .card-view-table-mode {
  display: inline-block !important;
  width: 3px;
}
.card-content-table-mode .card-table:nth-child(1) .subcard-view-table-mode {
  display: inline-block !important;
  width: calc(100% - 3px) !important;
}
.card-content-table-mode .card-table:nth-child(1) .subcard-view-table-mode .card-wrapper-table-mode {
  padding: 8px 0px 8px 0px;
}
.card-content-table-mode .card-table:nth-child(1) .mobile-mode.subcard-view-table-mode .card-wrapper-table-mode {
  padding: 15px 0px 15px 0px;
}

.dx-texteditor-input {
  padding: 0px 9px 0px;
}

.custom-text-search {
  height: 20px;
  font-size: 0.8rem;
  position: relative;
  border: 1px solid #b0b0b0 !important;
  border-radius: 2px !important;
  width: 145px;
  display: flex;
  align-items: center;
  cursor: text;
  overflow: hidden;
}

.browser-preview-content-scroll {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: text;
  /* supported by Chrome and Opera */
  -webkit-user-select: text;
  /* Safari */
  -moz-user-select: text;
  /* Firefox */
  -ms-user-select: text;
  /* Internet Explorer/Edge */
}

@-moz-document url-prefix() {
  .browser-preview-content-scroll {
    scrollbar-width: thin;
  }
}
.custom-text-search.dx-texteditor-empty .dx-clear-button-area {
  display: none !important;
}

.custom-text-search.dx-state-focused.dx-editor-outlined .dx-texteditor-container {
  background: #ffff80;
}
.custom-text-search.dx-state-focused.dx-editor-outlined .dx-placeholder {
  display: none !important;
}

.custom-input .dx-placeholder {
  display: flex;
  align-items: center;
}

.custom-text-search .dx-texteditor-container {
  display: flex;
  align-items: center;
}
.custom-text-search .dx-texteditor-container .dx-texteditor-input {
  font-weight: normal;
  min-height: unset;
  color: black;
}
.custom-text-search .dx-texteditor-container .dx-icon-clear:before {
  content: "";
}
.custom-text-search .dx-texteditor-container .dx-clear-button-area {
  width: 16px !important;
  display: flex;
  align-items: center;
  min-width: unset !important;
}
.custom-text-search .dx-texteditor-container .dx-icon-clear {
  font-size: 12px;
  color: #989393;
  top: unset;
  margin-top: unset;
  line-height: unset;
  padding: unset;
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
}
.custom-text-search .dx-texteditor-container .dx-placeholder {
  display: flex;
  align-items: center;
  font-weight: normal;
  color: #787675;
  font-style: italic;
}
.custom-text-search .dx-texteditor-container .dx-placeholder:before {
  padding: 0px 0px 0px 5px;
}

.toolbar-separator {
  background: #b0b0b0;
  width: 1px;
  height: 25px;
  display: inline-flex;
  margin-left: 4px;
  margin-right: 4px;
}

.content-filters {
  overflow: auto;
  width: calc(100% - 50px);
  display: flex;
  align-items: center;
}

@-moz-document url-prefix() {
  .content-filters {
    scrollbar-width: thin;
  }
}
.enabled-filter {
  display: inline-flex;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
  border-right: 1px solid #222;
  letter-spacing: 0.01rem;
  margin-left: 4px;
  background-color: #91cffb;
  height: 21px;
  padding-left: 4px;
  cursor: pointer;
  align-items: center;
}

.disabled-filter {
  display: inline-flex;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 1px solid #222;
  border-right: 1px solid #222;
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  margin-left: 4px;
  background-color: #dcdcdc;
  height: 21px;
  padding-left: 4px;
  cursor: pointer;
  align-items: center;
}

.btn-inner-text {
  padding: 0px !important;
  margin-left: -16px;
  margin-right: 2px;
  color: #aca7a3;
  font-size: 1rem;
  height: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.custom-btn-inner-text {
  padding: 0px !important;
  margin-right: 2px;
  color: #aca7a3;
  font-size: 1rem;
  height: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.list-ios {
  margin: 0px !important;
}

i.fas.fa-chevron-circle-right {
  font-size: 20px;
  margin-top: -2px;
  margin-right: 5px;
}

.list-toolbar i.fas.fa-list-alt {
  font-size: 20px;
  margin-top: -2px;
  margin-right: 2px;
}

.list-toolbar i.fas.fa-table {
  font-size: 20px;
  margin-top: -2px;
  margin-left: 2px;
}

i.fa.fa-rss {
  font-size: 20px;
}

.preview-wrapper .icon-svg-toolbar {
  margin-right: 10px;
  margin-left: 10px;
}

.card-margin {
  margin: 0px 0px 13px 0px;
}

.ion-list-card {
  margin-top: 10px !important;
  text-align: -webkit-center;
}

.group-length {
  font-weight: bold;
  width: 10%;
  margin-right: 5px;
  text-align: right;
}

.group-length-table-mode {
  float: right;
  font-weight: bold;
  margin-right: 5px;
}

.custom-dx-tabs-toolbar .dx-tabs-scrollable {
  margin-top: 0px;
}

.custom-dx-tabs-toolbar .dx-tabs-scrollable .dx-tabs-wrapper {
  border: none;
}

.custom-dx-tabs-toolbar .dx-tabs-scrollable .dx-scrollable-content {
  min-width: 100%;
  display: table;
  position: sticky;
}

.custom-dx-tabs-toolbar .dx-scrollable {
  height: auto;
}

.custom-radio-group .dx-widget {
  float: left;
  padding-right: 9px;
  padding-left: 7px;
}

.custom-check-box {
  text-align: left;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 2px;
  padding-left: 0px;
}

.custom-dx-tabs-toolbar .dx-tabs-nav-button {
  background: #2a5780;
  width: 25px;
  height: 48px;
}

.custom-dx-tabs-toolbar {
  position: static;
}
.custom-dx-tabs-toolbar .dx-tabs-nav-button-left.dx-state-disabled ~ .dx-tabs-scrollable {
  margin-left: 0px;
}
.custom-dx-tabs-toolbar .dx-tabs-scrollable {
  margin-right: 0px;
}

.dx-scrollable-native.dx-scrollable-native-generic {
  overflow: auto;
}

.custom-dx-tabs-toolbar .dx-state-disabled {
  display: none;
}

.custom-dx-tabs-toolbar .dx-tabs-nav-button .dx-button-content .dx-icon {
  color: white;
}

.custom-dx-tabs-toolbar {
  border: none;
  display: inline-block !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.custom-dx-tabs-toolbar .dx-tabs-wrapper .dx-item .dx-item-content .dx-tab-text {
  text-overflow: unset;
}

.custom-dx-tabs-toolbar .dx-tabs-wrapper .dx-item {
  background-color: var(--bw2-darksoft);
  color: white;
  padding: 0px 0px 0px 0px;
  height: 52px;
  position: static;
}

.custom-dx-tabs-toolbar .dx-tabs-wrapper .dx-tab {
  display: inline-flex;
}

.custom-dx-tabs-toolbar .dx-tabs-wrapper .dx-tab-selected {
  color: white;
}

.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 0 !important;
  border: 1px solid #abd8e7 !important;
  outline: unset !important;
  padding-top: inherit;
  background-color: inherit;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.custom-pop-up.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 0 !important;
  border: 4px solid #436d98 !important;
  outline: 1px solid #abd8e7 !important;
}

.mobile-mode-app .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #abd8e7 !important;
  outline: unset !important;
  padding-top: inherit;
  background-color: inherit;
}
.mobile-mode-app .dx-popup-title {
  background-color: var(--bgbw2) !important;
}

.dx-datebox-wrapper .dx-popup-normal {
  background-color: white !important;
}
.dx-datebox-wrapper .dx-popup-title {
  background-color: white !important;
  padding: 6px 20px !important;
}

.dx-popup-bottom {
  background: #ffffff;
}

.content-button-close {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
}

.custom-submenu {
  position: absolute;
  border: 1px solid #dedede;
  background: white;
  width: 250px;
  z-index: 3000;
  top: 0px;
  right: 108px;
  display: grid;
}

.right-ios-submenu {
  right: 113px;
}

.add-browse-filter-button {
  height: 18px;
  padding-left: 4px;
  cursor: pointer;
  width: auto;
}

.right-android-submenu {
  right: 101px;
}

@media (max-width: 399px) {
  .dx-scheduler-time-panel {
    font-size: 0.5rem !important;
  }
}
.fr-popup.fr-active {
  z-index: 999999 !important;
}

.custom-scroll-view-preview-container .dx-scrollable-content {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: unset !important;
}

.mobile-mode ion-header ion-toolbar:first-child {
  padding-top: calc(constant(safe-area-inset-top) - 55px);
  padding-top: calc(env(safe-area-inset-top) - 55px);
}
.mobile-mode ion-toolbar {
  height: 55px;
}
.mobile-mode ::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.mobile-mode .toolbar-separator.mobile {
  display: none;
}
.mobile-mode .edit-item-scrollable .dx-scrollable-scroll-content {
  display: none !important;
}
.mobile-mode .counts-bar-menu {
  height: 30px;
}
.mobile-mode .icon-svg-toolbar {
  margin-right: 4px;
}
.mobile-mode .btn-inner-text {
  font-size: 1.2rem;
  margin-left: -31px;
  padding: 0px 0px 0px 5px !important;
  height: 100%;
  width: 30px;
  justify-content: center;
}
.mobile-mode .custom-btn-inner-text {
  font-size: 1.2rem;
}
.mobile-mode .btn-detail-toolbar .fa {
  font-size: 24px;
}
.mobile-mode .btn-detail-toolbar.mobile {
  display: none;
}
.mobile-mode .context-menu-text {
  min-height: 30px !important;
  line-height: 30px;
}
.mobile-mode .context-menu-icon {
  padding-left: 6px;
}
.mobile-mode .btn-toolbar {
  margin-left: 4px;
  margin-right: 4px;
}
.mobile-mode .btn-toolbar .fa {
  font-size: 1.2rem;
}
.mobile-mode .btn-toolbar.mobile {
  display: none;
}
.mobile-mode .btn-txt-toolbar {
  font-size: 0.9rem;
}
.mobile-mode .btn-txt-toolbar .icon {
  font-size: 1.25rem;
}
.mobile-mode .counts-bar {
  top: 4px !important;
}
.mobile-mode i.fas.fa-chevron-circle-right {
  margin-right: 0px;
}
.mobile-mode .list-toolbar i.fas.fa-list-alt {
  margin-right: 0px;
}
.mobile-mode .list-toolbar i.fas.fa-table {
  margin-left: 0px;
}
.mobile-mode .preview-wrapper .icon-svg-toolbar {
  margin-right: 4px;
  margin-left: 4px;
}
.mobile-mode .add-browse-filter-button {
  height: 26px;
  padding-left: 4px;
  cursor: pointer;
  width: auto;
}
.mobile-mode .edit-item-scrollable .dx-scrollable-wrapper .dx-scrollable-container .dx-scrollable-content .dx-scrollview-content > .group-container:nth-child(1) {
  margin-top: 10px !important;
}
.mobile-mode .filter-caption {
  display: -webkit-inline-box !important;
  -webkit-line-clamp: 2;
  line-height: normal;
  overflow: hidden;
  white-space: normal !important;
}
.mobile-mode .enabled-filter {
  height: 35px;
}
.mobile-mode .disabled-filter {
  height: 35px;
}
.mobile-mode .content-filters {
  width: calc(100% - 25px);
}
.mobile-mode .custom-text-search {
  height: 30px;
}
.mobile-mode .counts-bar-list-toolbar .count-bar-right {
  font-size: 0.9rem;
}
.mobile-mode .counts-bar-list-toolbar .count-bar-right-1 {
  font-size: 0.9rem;
}
.mobile-mode .counts-bar-list-toolbar .count-bar-right-0 {
  font-size: 0.9rem;
}
.mobile-mode .counts-bar-list-toolbar .count-bar-left {
  font-size: 0.9rem;
}
.mobile-mode .counts-bar-list-toolbar .count-bar-left-2 {
  font-size: 0.9rem;
}
.mobile-mode .ion-list-card {
  margin-top: 2px !important;
}
.mobile-mode .card-margin {
  margin: 0px 0px 6px 0px;
}
.mobile-mode .dx-popup-bottom .dx-button {
  min-width: 80px !important;
}
.mobile-mode .item-context-menu {
  height: 45px;
}
.mobile-mode .context-menu-icon {
  padding-left: 3px;
}
.mobile-mode .context-menu-icon .custom-fa {
  font-size: 21px;
}
.mobile-mode .custom-dx-tabs-toolbar .dx-tabs-wrapper .dx-item {
  height: 55px;
}
.mobile-mode .custom-dx-tabs-toolbar .dx-tabs-nav-button {
  height: 51px;
}
.mobile-mode .counts-bar-plugin {
  padding-top: 7px;
}

@media only screen and (min-width: 100px) and (min-height: 100px) {
  .modal-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.dx-context-menu .dx-menu-item .dx-submenu {
  display: none;
}

/*Cambio especial para Juver*/
.BW2-container-fluid {
  overflow-x: unset !important;
}

.tooltip-container {
  background-color: #FFFFE0;
  text-align: left;
  padding: 5px;
}

.custom-cell-text {
  font-size: 0.75rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  display: -webkit-inline-box;
  line-height: normal;
  overflow: hidden;
  white-space: pre !important;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
  width: 8px !important;
}

.dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-overlay-content, .dx-scheduler .dx-scheduler-overlay-panel .dx-overlay-content {
  background-color: #F2F2F2 !important;
}

.dx-scheduler-appointment-tooltip-wrapper .dx-overlay-content .dx-popup-content .dx-list-item {
  border: 0.5px solid #808080 !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  margin-left: 4px !important;
  margin-right: 11px !important;
}

.dx-scheduler-work-space-day .dx-scheduler-appointment-reduced .dx-scheduler-appointment-content, .dx-scheduler-work-space-week .dx-scheduler-appointment-reduced .dx-scheduler-appointment-content, .dx-scheduler-work-space-work-week .dx-scheduler-appointment-reduced .dx-scheduler-appointment-content {
  padding-right: 0px !important;
}

.fr-popup {
  position: fixed !important;
}

@media only screen and (max-width: 1024px) {
  .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item, .dx-scheduler .dx-scheduler-overlay-panel .dx-popup-content .dx-list-item {
    width: 250px !important;
  }
}
@media only screen and (max-width: 600px) {
  .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item, .dx-scheduler .dx-scheduler-overlay-panel .dx-popup-content .dx-list-item {
    width: 200px !important;
  }
}
@media only screen and (max-height: 450px) {
  .dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
    max-height: 104px !important;
  }
}
.dx-scheduler-work-space:not(.dx-scheduler-work-space-vertical-grouped) .dx-scrollable.dx-scheduler-date-table-scrollable, .dx-scheduler-work-space:not(.dx-scheduler-work-space-vertical-grouped) .dx-scrollable.dx-scheduler-sidebar-scrollable {
  padding-bottom: 20px !important;
}

.dx-scheduler-agenda.dx-scheduler-work-space .dx-scrollable.dx-scheduler-date-table-scrollable, .dx-scheduler-agenda.dx-scheduler-work-space .dx-scrollable.dx-scheduler-sidebar-scrollable {
  padding-bottom: 0px !important;
}

.dx-scheduler-work-space-day:not(.dx-scheduler-work-space-vertical-grouped) .dx-scrollable.dx-scheduler-date-table-scrollable, .dx-scheduler-work-space-day:not(.dx-scheduler-work-space-vertical-grouped) .dx-scrollable.dx-scheduler-sidebar-scrollable {
  padding-bottom: 0px !important;
}

.iphonex-mode .dx-scheduler-work-space:not(.dx-scheduler-work-space-vertical-grouped) .dx-scrollable.dx-scheduler-date-table-scrollable, .iphonex-mode .dx-scheduler-work-space:not(.dx-scheduler-work-space-vertical-grouped) .dx-scrollable.dx-scheduler-sidebar-scrollable {
  padding-bottom: 50px !important;
}

.custom-screen {
  height: 100%;
  position: relative;
  background: black;
  z-index: 100000000000000010000000000000;
}